/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.StaffPage,
.StaffUserPage {
  background: #f9f6ef;
  padding: 1rem;
  text-align: center;
}
.StaffPage pre,
.StaffUserPage pre {
  text-align: left;
}
.StaffPage h1,
.StaffUserPage h1 {
  font-size: 3em;
  font-weight: 600;
  color: #745a9e;
  font-family: 'Zona Pro', 'Verdana';
  text-align: center;
}
@media (max-width: 991px) {
  .StaffPage h1,
  .StaffUserPage h1 {
    font-size: 2.2em;
    margin-left: 1rem;
  }
}
.StaffPage h2,
.StaffUserPage h2 {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 4rem;
  color: #745a9e;
}
@media (max-width: 991px) {
  .StaffPage h2,
  .StaffUserPage h2 {
    font-size: 1.6rem;
  }
}
.StaffPage .inner .content,
.StaffUserPage .inner .content {
  padding: 1.5rem;
  margin: 3rem;
  background: #fff;
  border-radius: 0.7rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .StaffPage .inner .content,
  .StaffUserPage .inner .content {
    margin: 0;
  }
}
.StaffPage .inner .content tr,
.StaffUserPage .inner .content tr {
  height: 4rem;
}
.StaffPage .UserSearchForm,
.StaffUserPage .UserSearchForm {
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .StaffPage .UserSearchForm,
  .StaffUserPage .UserSearchForm {
    width: 100%;
  }
}
